.btn {
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all .2s;

  font-size: 18px;
  line-height: 20px;
  padding: 0 15px;
  min-height: 44px;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 18px;
    padding: 0 13px;
  }
  &--lg {
    font-size: 24px;
    line-height: 32px;
    padding: 0 20px;
    min-height: 58px;
    @media (max-width: 768px) {
      min-height: 40px;
      font-size: 14px;
      line-height: 18px;
      padding: 0 16px;
    }
  }

  background: #FFDE40;
  &:hover,
  &:active {
    background: #FFF3C3;
  }
  &:disabled {
    background: #EBEBEB;
    color: #C6C6CE;
    cursor: default;
    &:hover,
    &:active {
      background: #EBEBEB;
      color: #C6C6CE;
    }
  }

  &--outline {
    border: 1px solid #222222;
    background-color: transparent;
    &:hover,
    &:active {
      background: #222222;
      color: #FFFFFF;
    }
    &:disabled {
      border-color: #EBEBEB;
      &:hover,
      &:active {
        background-color: transparent;
        color: #222222;
      }
    }
  }

  &--gray {
    background-color: #F8F8F8;
    &:hover,
    &:active {
      background: #EBEBEB;
    }
    &:disabled {
      background-color: #F8F8F8;
      &:hover,
      &:active {
        background-color: #F8F8F8;
      }
    }
    &.active {
      background-color: #70747F;
      color: #FFFFFF;
      &:hover,
      &:active {
        background-color: darken(#70747F, 5);
      }
    }
  }

  &--blue {
    background-color: #237DC6;
    color: #FFFFFF;
    &:hover,
    &:active {
      background: #66A7F6;
      color: #FFFFFF;
    }
    &:disabled {
      background-color: #F8F8F8;
      &:hover,
      &:active {
        background: #F8F8F8;
      }
    }
  }

  &--link {
    background-color: transparent;
    color: #237DC6;
    &:hover,
    &:active {
      background-color: transparent;
      color: #66A7F6;
    }
    &:disabled {
      background-color: transparent;
      &:hover,
      &:active {
        background-color: transparent;
      }
    }
  }

  border-radius: 4px;
  &--pill {
    border-radius: 8px;
  }
}