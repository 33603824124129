.map-button {
  width: 60px;
  height: 60px;
  background: #FFFFFF;
  box-shadow: 0px 2px 2px rgba(112, 116, 127, 0.05), 0px 4px 4px rgba(112, 116, 127, 0.25);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all .3s;

  &:hover,
  &:active {
    box-shadow: 0px 4px 4px rgba(112, 116, 127, 0.05), 0px 6px 8px rgba(112, 116, 127, 0.3);
  }

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    svg {
      width: 16px;
      height: 16px;
    }
  }
}