.map-page-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  z-index: 2;
  @media (max-width: 768px) {
    right: 8px;
  }
}

.map-page-control__plus {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.map-page-control__minus {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.map-page-control__geolocation {
  margin-top: 12px;
  @media (max-width: 768px) {
    margin-top: 64px;
  }
}