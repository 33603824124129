.select-region__group {
  position: relative;
  border-bottom: 1px solid #C6C6CE;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.select-region__input {
  flex: auto;
  height: 50px;
  font-size: 20px;
  @media (max-width: 768px) {
    height: 30px;
    font-size: 14px;
  }
}

.select-region__clear,
.select-region__next {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 34px;
    height: 34px;
    svg {
      width: 12px;
      height: 12px;
    }
  }
}

.select-region__clear {
  transition: all .2s;
  svg {
    fill: #C6C6CE;
  }
  &:hover {
    svg {
      fill: #222222;
    }
  }
  &:disabled {
    cursor: default;
    svg {
      fill: #C6C6CE;
    }
  }
}

.select-region__next {
  background-color: #FFDE40;
  svg {
    fill: #222222;
  }

  &:hover,
  &:active {
    transition: all .2s;
    background-color: #FFF3C3;
  }

  &:disabled {
    cursor: default;
    background-color: transparent;
    svg {
      fill: #C6C6CE;
    }
  }
}

.select-region__wrap {
  position: relative;
  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    height: 40px;
    left: 0;
    right: 0;
    pointer-events: none;
  }
  &:before {
    top: -20px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
  }
  &:after {
    bottom: -20px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
  }
}

.select-region__list {
  max-height: 255px;
  overflow-y: auto;
  padding-top: 10px;
  margin-bottom: -20px;
  @media (max-width: 768px) {
    max-height: 240px;
  }
}

.select-region__item {
  button {
    font-size: 20px;
    width: 100%;
    padding: 8px 0;
    display: flex;
    cursor: pointer;
    background-color: transparent;
    color: #70747F;
    &:hover {
      color: #222222;
    }
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
}

.select-region__empty {
  font-size: 20px;
  color: #222222;
  @media (max-width: 768px) {
    font-size: 14px;
  }
}