.button-group {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 0 -4px;
  .btn {
    margin: 0 4px;
    margin-bottom: 8px;
  }

  &--disabled {
    pointer-events: none;
  }
}