.map-point {
  margin-top: 6px;
  min-height: calc(100% - 8px);
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    min-height: auto;
    margin-bottom: -26px;
    max-height: calc(100vh - 124px);
  }
}

.map-pint__descr {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #70747F;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 13px;
  }
}

.map-point__action {
  margin: 24px 0;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    margin-bottom: 16px;
  }
}

.map-point__trip {
  align-self: flex-start;
  display: inline-flex;
  font-size: 16px;
  margin-right: 16px;
  svg {
    fill: #fff;
    margin-right: 12px;
  }
  @media (max-width: 768px) {
    margin-right: 8px;
    font-size: 14px;
    padding: 0 8px;
    svg {
      margin-right: 8px;
    }
  }
}

.map-point__education {
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
}

.map-point__buttons {
  padding: 16px 30px;
  border-top: 1px solid #EBEBEB;
  margin-left: -30px;
  width: calc(100% + 60px);
  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
    width: calc(100% + 32px);
    margin-left: -16px;
  }
}

.map-point__list {
  flex: auto;
  padding-bottom: 24px;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: -30px;
  width: calc(100% + 60px);
  border-top: 1px solid #EBEBEB;
  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
    width: calc(100% + 32px);
    margin-left: -16px;
    display: none;
    &--swipe {
      padding-bottom: 0;
      display: block;
    }
  }
}

.map-point__item {
  display: flex;
  align-items: center;
  padding: 16px 0;
  font-size: 16px;
  line-height: 24px;
  position: relative;
  background-color: transparent;
  width: 100%;
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #EBEBEB;
    position: absolute;
    bottom: 0;
    left: 30px;
  }
  svg {
    width: 20px;
    margin-right: 10px;
    flex: none;
  }
  .btn.btn--link {
    font-size: 16px;
    line-height: 24px;
    padding: 0;
    min-height: auto;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
    &:before {
      width: calc(100% - 12px);
    }
  }

  &--with-arrow {
    cursor: pointer;
    &:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 6px 0 6px;
      border-color: #C6C6CE transparent transparent transparent;
      margin-left: auto;
      transition: all .2s;
    }
  }
  &--active {
    &:after {
      transform: rotate(180deg);
    }
  }
}

.map-point__time-list {
  margin-top: 16px;
  margin-bottom: 24px;
  padding: 0 30px;
  @media (max-width: 768px) {
    padding-right: 0;
    margin-bottom: 16px;
  }
}

.map-point__time-item {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  line-height: 24px;
  &+& {
    margin-top: 16px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
}

.map-point__footer {
  width: calc(100% + 60px);
  margin-left: -30px;
  padding: 18px 30px 28px;
  background-color: #F8F8F8;
  font-size: 14px;
  line-height: 20px;
  color: #70747F;
  @media (max-width: 768px) {
    padding: 16px;
    font-size: 12px;
    line-height: 16px;
    width: calc(100% + 32px);
    margin-left: -16px;
  }
}

.map-point__footer-copy {
  margin-bottom: 16px;
  display: block;
}