.map-block {
  position: fixed;
  left: 0;
  bottom: 0;
  top: 80px;
  width: 100%;
  max-width: 440px;
  padding: 30px;
  box-shadow: 0px 2px 2px rgba(112, 116, 127, 0.05), 0px 4px 4px rgba(112, 116, 127, 0.25);
  overflow-y: auto;
  transform: translateX(-100%);
  transition: all .3s;
  background: #fff;
  &--active {
    transform: translateX(0);
  }

  .map-point__list--swipe {
    display: none;
  }
  
  @media (max-width: 768px) {
    transform: translateY(100%);
    padding: 26px 8px;
    top: unset;
    max-width: 100%;
    min-height: 200px;
    max-height: calc(100vh - 50px);
    overflow-y: none;
    &--swipe {
      overflow-y: auto;
      .map-point__list--swipe {
        display: block;
      }
      &:after {
        content: '';
        display: block;
        width: 16px;
        height: 2px;
        background: #C6C6CE;
        border-radius: 1px;
        position: absolute;
        top: 4px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &--active {
      transform: translateY(0);
      .map-point__list {
        display: block;
        &--swipe {
          display: none;
        }
      }
    }
  }
}

.map-block__swipe {
  display: none;
  @media (max-width: 768px) {
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

.map-block__close {
  width: 40px;
  height: 40px;
  background-color: transparent;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  svg {
    fill: #70747F;
  }
  &:hover {
    svg {
      fill: #222222;
    }
  }
  @media (max-width: 768px) {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #C4C4C4;
    top: 32px;
    right: 10px;
    z-index: 5;
    svg {
      width: 10px;
      height: 10px;
      fill: #FFFFFF;
    }
  }
}

.map-block__content {
  height: 100%;
  position: relative;
  z-index: 2px;
}

.map-block__title {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 28px;
  }
}