.map-page-header {
  background: #FFFFFF;
  box-shadow: 0px 2px 2px rgba(112, 116, 127, 0.05), 0px 4px 4px rgba(112, 116, 127, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;

  @media (min-width: 769px) {
    &.map, &.main {
      .map-page-header__nav a {
        color: #70747F;

        &.active {
          color: #222 !important;
          cursor: default;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .container {
      padding: 0;
    }
  }
}

.map-page-header__wrap {
  display: flex;
  align-items: center;
  height: 90px;
  @media (max-width: 768px) {
    height: 48px;
  }
}

.map-page-header__menu {
  display: none;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
  line-height: 16px;
  color: #222;
  text-transform: uppercase;
  background-color: #FFDE40;
  width: 48px;
  height: 48px;
  margin-right: 15px;
  cursor: pointer;
  svg {
    width: 14px;
    height: 14px;
    fill: #000;
  }
  @media (max-width: 768px) {
    display: flex;
  }
}

.map-page-header__logo {
  svg {
    width: 150px;
  }
  @media (max-width: 768px) {
    svg {
      width: 87px;
    }
  }
}

.map-page-header__region {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
  background-color: transparent;
  margin-right: auto;
  margin-left: 16px;
  svg {
    margin-right: 14px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    margin-right: 16px;
    margin-left: auto;
    svg {
      margin-right: 10px;
    }
  }
}

.map-page-header__nav {
  display: flex;
  align-items: center;
  a {
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: #222;
    padding: 10px;
    &:hover {
      color: #000 !important;
    }
  }
  @media (max-width: 768px) {
    display: none;
    background-color: #222;
    position: fixed;
    top: 48px;
    left: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;
    &.open {
      display: flex !important;
    }
    a,
    a:visited,
    a:focus,
    a:hover {
      text-align: left;
      width: 100%;
      color: #fff !important;
      font-size: 16px;
      line-height: 32px;
      border-bottom: 1px solid #2D2D2D;
      padding: 0 16px;
      height: 60px;
      display: flex;
      align-items: center;
      &:hover {
        color: #fff;
      }
    }
  }
}

.select-region-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  background: rgba(#70747F, .4);
}

.select-region-content {
  padding: 40px;
  background: #FFFFFF;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 585px;
  @media (max-width: 768px) {
    padding: 20px 20px 40px;
    max-width: calc(100vw - 20px);
  }
}

.select-region-content__clear {
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  top: 30px;
  right: 30px;
  transition: all .2s;
  svg {
    fill: #C6C6CE;
  }
  &:hover {
    svg {
      fill: #222222;
    }
  }
}

.select-region-content__title {
  margin-top: 40px;
  margin-bottom: 50px;
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  color: #222222;
  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 34px;
    margin: 20px 0;
  }
  @media (max-width: 480px) {
    font-size: 18px;
    line-height: 26px;
  }
}