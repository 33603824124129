.map-page-filters {
  z-index: 3;
  position: relative;
}

.map-page-filters__open {
  position: absolute;
  top: 20px;
  left: 40px;

  @media (max-width: 768px) {
    position: fixed;
    top: 68px;
    left: 8px;
  }

  &--active {
    left: 460px;
    @media (max-width: 768px) {
      left: 8px;
    }
  }

  &--fill {
    &:after {
      content: '';
      display: block;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background-color: #237DC6;
      border: 2px solid #FFFFFF;
      position: absolute;
      top: 19px;
      right: 13px;
      @media (max-width: 768px) {
        width: 8px;
        height: 8px;
        top: 12px;
        right: 8px;
      }
    }
  }
}

.map-page-filters__content {
  margin-top: 24px;
  height: calc(100% - 96px - 56px + 30px);
  min-height: 200px;
  @media (max-width: 768px) {
    min-height: auto;
    height: auto;
  }
}

.map-page-filters__actions {
  height: 96px;
  border-top: 1px solid #EBEBEB;
  margin-left: -30px;
  width: calc(100% + 60px);
  padding: 0 30px;
  display: flex;
  align-items: center;
}

.map-page-filters__empty {
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  margin-top: 40px;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
    margin-top: 12px;
    margin-bottom: 24px;
  }
}