.main__hero {
  background: url('./bg.jpg') center no-repeat;
  background-size: cover;
  padding: 60px 0 50px;
  color: #fff;
  @media (max-width: 768px) {
    background-image: linear-gradient(32.55deg, rgba(34, 34, 34, 0.6) 0%, rgba(34, 34, 34, 0.3) 101.89%), url('./bg-mobile.jpg');
    background-size: cover;
    padding: 60px 0 40px;
    min-height: 300px;
    background-position: center, top;
  }
}

.main__title {
  font-weight: bold;
  font-size: 100px;
  line-height: 120px;
  margin-bottom: 66px;
  max-width: 730px;
  @media (max-width: 768px) {
    font-size: 52px;
    line-height: 62px;
    margin-bottom: 30px;
  }
}

.main__descr {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  max-width: 790px;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
}

.main__footer {
  padding: 70px 0 80px;
  @media (max-width: 768px) {
    padding: 30px 0;
  }
}

.main__footer-wrap {
  display: flex;
  @media (max-width: 768px) {
    display: block;
  }
}

.main__footer-title {
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  width: 100%;
  max-width: 380px;
  margin-right: 30px;
  flex: none;
  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 25px;
    padding-left: 15px;
  }
}

.main__footer-text {
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  .title {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }
  p:not(.title) {
    padding-left: 30px;
  }
  p.title + p {
    margin-top: 10px;
  }
  p + p.title {
    margin-top: 40px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
    .title {
      font-size: 14px;
      line-height: 20px;
    }
    p:not(.title) {
      padding-left: 15px;
    }
    p + p.title {
      margin-top: 25px;
    }
  }
}

.main-block {
  padding: 40px 10px 40px 0;
  min-height: 370px;
  @media (max-width: 768px) {
    min-height: auto;
    padding-bottom: 0;
  }
}

.main-block__title {
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 32px;
  }
}

.main-block__subtitle {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
  }
}

.main-block__actions {
  display: flex;
  & > .btn + .btn {
    margin-left: 16px;
    @media (max-width: 768px) {
      margin-left: 8px;
    }
  }
}

.main-block__select {
  max-height: calc(100% - 160px);
}